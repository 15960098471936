/* --------------------------------------- */
/* STORE CONFIGURATION
/* --------------------------------------- */

const HOST = {
    local: 'http://localhost:3000',
    dev: 'https://pwa-oms-wacoalsg-linode.testingnow.me',
    stage: 'https://omsdemo-pwa.gcp-staging.testingnow.me',
    prod: 'https://oms-wacoalsg.swift-oms.com',
};
// const HOST = {
//     local: 'http://localhost:3000',
//     dev: 'http://localhost:3000',
//     stage: 'http://localhost:3000',
//     prod: 'http://localhost:3000',
// };

/* Magento GraphQL Endpoint */
const graphqlEndpoint = {
    local: 'https://oms-wacoalsg-linode.testingnow.me/graphql',
    dev: 'https://oms-wacoalsg-linode.testingnow.me/graphql',
    stage: 'https://oms-demo.gcp-staging.testingnow.me/graphql',
    prod: 'https://wacoalsg.mgt.swift-oms.com/graphql',
};

/* --------------------------------------- */
/* FEATURES CONFIGURATION
/* --------------------------------------- */

const installMessage = 'Get our free app.';
const appName = 'Swift APP';

/* Social Sharing */
const shareIcon = {
    facebook: true,
    twitter: true,
    line: true,
    email: true,
    telegram: true,
    pinterest: false,
    linkedin: false,
};

/* Password Validator */
const passwordStrength = {
    minValue: 8,
    maxValue: 20,
    numberOfRequiredClass: 3, // Lowercase + Uppercse + Dgits + spesial caracter = 4
};

/* Translation */
const translation = {
    defaultLanguage: 'en', // just change to your default language
    languages: ['en', 'id'], // array code language what you want
    // language label code
    languagesLabel: {
        en: 'English',
        id: 'Indonesia',
    },
};

/* Google Tag Manager
 * before enable this configuration, firstly you need to import the gtm tags json.
 * gtm tags json need to be exported from Magento admin in Welpixel GTM configuration.
 * adjust the tag name if you want before import into GTM dashboard setting.
 * as reference you can find sample gtm tags in folder "sample/gtm" folder
 * NOTE: this GTM functionality includes connecting to GA via GTM tag.
 */
const GTM = {
    enable: false,
    gtmId: {
        local: '', // sample: GTM-N76V8KQ
        dev: '', // sample: GTM-N76V8KQ
        stage: '', // sample: GTM-N76V8KQ
        prod: '', // sample: GTM-N76V8KQ
    },
};

/* Recapthca Configuration */
const recaptcha = {
    enable: false,
    siteKey: {
        local: '', // sample: 6LcZmLEZAAAAADkdlp8S8tExis2EVhNiuMv6ayo7
        dev: '', // sample: 6LcZmLEZAAAAADkdlp8S8tExis2EVhNiuMv6ayo7
        stage: '', // sample: 6LcZmLEZAAAAADkdlp8S8tExis2EVhNiuMv6ayo7
        prod: '', // sample: 6LcZmLEZAAAAADkdlp8S8tExis2EVhNiuMv6ayo7
    },
    serverKey: {
        local: '', // sample: 6LcZmLEZAAAAANHhkqwoRna2vqIVWHNKwOvxm26n
        dev: '', // sample: 6LcZmLEZAAAAANHhkqwoRna2vqIVWHNKwOvxm26n
        stage: '', // sample: 6LcZmLEZAAAAANHhkqwoRna2vqIVWHNKwOvxm26n
        prod: '', // sample: 6LcZmLEZAAAAANHhkqwoRna2vqIVWHNKwOvxm26n
    },
};

const sentry = {
    enabled: false,
    enableMode: 'production',
    dsn: {
        local: 'https://9700d1051b5b4e13a450411af92303e2@o451158.ingest.sentry.io/5436645',
        dev: 'https://9700d1051b5b4e13a450411af92303e2@o451158.ingest.sentry.io/5436645',
        stage: 'https://9700d1051b5b4e13a450411af92303e2@o451158.ingest.sentry.io/5436645',
        prod: 'https://9700d1051b5b4e13a450411af92303e2@o451158.ingest.sentry.io/5436645',
    },
};

/* Loader */
const loaderImage = '/assets/img/loader.svg';

/* --------------------------------------- */
/* LOCAD DATA CACHE & COKIES
/* --------------------------------------- */

const expiredCookies = 6;
const storeConfigNameCookie = 'storeConfig';
const nameCartId = 'nci';
const custDataNameCookie = 'cdt';
const nameCheckoutCookie = 'ccdt';
const nameGlobalCookie = 'spwa';
const nameToken = 'sk';
const expiredToken = new Date(Date.now() + 1000 * 60 * 60);
const expiredDefault = 365;
const localResolverKey = 'resolver';

const features = {
    ssrCache: false,
    facebookMetaId: {
        enabled: false,
        app_id: '', // if enabled add fb app id here. e.g. 3080154482073095
    },
    vesMenu: {
        enabled: true,
    },
    customInstallApp: {
        enabled: true,
    },
    chatSystem: {
        enabled: false,
        graphqlEndpoint: {
            local: 'https://chat-swift.testingnow.me/graphql',
            dev: 'https://chat-swift.testingnow.me/graphql',
            stage: 'https://chat-swift.testingnow.me/graphql',
            prod: 'https://chat-swift.testingnow.me/graphql',
        },
        graphqlContext: 'chatSystem',
        config: {
            apiKey: 'AIzaSyCD0ZuTMcNi3PSsJH9LD21v7_XA1sVLjdI', // sample: AIzaSyCt9ks21BjiE9qirv-8xOEcUnUnH6viobw
            authDomain: 'swiftpwa-firebase.firebaseapp.com', // sample: swift-pwa-dev.firebaseapp.com
            projectId: 'swiftpwa-firebase', // sample: swift-pwa-dev
            databaseURL: 'https://swiftpwa-firebase.firebaseio.com', // sample: https://swiftpwa-firebase.firebaseio.com
            storageBucket: 'swiftpwa-firebase.appspot.com', // sample: swift-pwa-dev.appspot.com
            messagingSenderId: '731430387766', // sample: 1083571104838
            appId: '1:731430387766:web:af85ac9f9559c873309897', // sample: 1:1083571104838:web:4f06ca5a60b1c1a9efee47
            measurementId: 'G-DP22E2CL8G', // sample: G-HBHPY22H0H
        },
    },
    // pushNotification: {
    //     enabled: false,
    //     config: {
    //         apiKey: 'AIzaSyBwAPEXdjKf84q-T7tUxVJBcOJJ8hzrXTI',
    //         authDomain: 'swift-pwa.firebaseapp.com',
    //         databaseURL: 'https://swift-pwa.firebaseio.com',
    //         projectId: 'swift-pwa',
    //         storageBucket: 'swift-pwa.appspot.com',
    //         messagingSenderId: '1029426161575',
    //         appId: '1:1029426161575:web:2c57e3f74cb00e0132f882',
    //         measurementId: 'G-VSRV1DJVSQ',
    //     },
    // },
};

const nossrCache = [
    '/graphql',
];

const debuging = {
    originalError: false,
};

/* Translation CSV Dir */
const translationCSVDir = 'public/static/locales_csv/';
const translationJSONDir = 'public/static/locales/';

module.exports = {
    sentry,
    debuging,
    GTM,
    HOST,
    graphqlEndpoint,
    shareIcon,
    passwordStrength,
    translation,
    expiredCookies,
    storeConfigNameCookie,
    nameCartId,
    nameToken,
    expiredToken,
    expiredDefault,
    loaderImage,
    custDataNameCookie,
    nameCheckoutCookie,
    nameGlobalCookie,
    features,
    nossrCache,
    recaptcha,
    installMessage,
    appName,
    localResolverKey,
    translationCSVDir,
    translationJSONDir,
};
